import React from 'react'
import { SvgIcon } from '@material-ui/core'

const TikTok = React.memo(
  React.forwardRef((props, ref) => (
    <SvgIcon ref={ref} {...props}>
      <path
        d="M21.7,0H2.3C1,0,0,1,0,2.2v19.5C0,23,1,24,2.3,24h19.5c1.2,0,2.3-1,2.3-2.2V2.2C24,1,23,0,21.7,0z M14.9,4.5
	c0.3,2.1,1.4,3.4,3.5,3.5v2.4c-1.2,0.1-2.3-0.3-3.5-1v4.5c0,5.7-6.2,7.5-8.7,3.4c-1.6-2.6-0.6-7.2,4.5-7.4v2.5
	c-0.4,0.1-0.8,0.2-1.2,0.3c-1.1,0.4-1.8,1.1-1.6,2.4c0.3,2.5,4.9,3.2,4.5-1.6v-9L14.9,4.5L14.9,4.5z"
      />
    </SvgIcon>
  ))
)
TikTok.displayName = 'TikTokIcon'
TikTok.uiName = 'TikTokIcon'

export default TikTok
