import React from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
} from 'react-router-dom'
import { ThemeProvider } from '@material-ui/styles'
import { CssBaseline } from '@material-ui/core'
import createTheme from 'components/styles/createTheme'
import AppContainer from 'containers/App'
import 'components/Videosphere'

const App = () => {
  return (
    <Router>
      <ThemeProvider theme={createTheme()}>
        <CssBaseline />
        <AppContainer />
      </ThemeProvider>
    </Router>
  )
}

const rootElement = document.getElementById('root')

ReactDOM.render(<App />, rootElement)
