import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { CircularProgress, Typography } from '@material-ui/core'
import Container from 'components/Container'
import useWP from 'components/useWP'

export const styles = theme => ({
  root: {
    '& figure': {
      margin: 0,
    },
    '& img': {
      maxWidth: '100%',
    },
  },
})
const Page = props => {
  const { slug, classes } = props
  const pages = useWP(
    React.useCallback(wp => wp.pages().slug(slug), [slug]),
    false,
    `page_${slug}`
  )

  if (!pages) {
    return (
      <div>
        <CircularProgress />
      </div>
    )
  }

  const page = pages[0]

  return (
    <Container className={classes.root}>
      <Typography
        component="div"
        className={classes.root}
        dangerouslySetInnerHTML={{
          __html: page && page.content && page.content.rendered,
        }}
      />
    </Container>
  )
}

Page.propTypes = {
  classes: PropTypes.object.isRequired,
  slug: PropTypes.string,
}

Page.uiName = 'Page'

export default withStyles(styles)(Page)
