import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Card, Typography } from '@material-ui/core'
import withStyles from '@material-ui/styles/withStyles'

export const styles = theme => ({
  root: {
    display: 'block',
    position: 'relative',
    minHeight: 150,
  },
  media: {
    position: 'relative',
    width: '100%',

    '& > div': {
      paddingBottom: `${((9 / 16) * 100).toFixed(2)}%`,
    },
    '& > img': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',

      objectFit: 'cover',
    },
  },
  title: {
    position: 'absolute',
    bottom: 0,

    color: 'white',
    mixBlendMode: 'difference',

    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textShadow: '3px 3px blue',
    textStroke: '1px red',
  },
  variant1: {
    transform: 'skewX(-16deg) rotate(3deg)',
    color: 'black',
  },
  variant2: {
    transform: 'skewX(-50deg) scaleX(1.3) translateX(32px)',
  },
  variant3: {
    transform: 'skewX(16deg) rotate(-3deg)',
    color: 'black',
  },
  variant4: {
    transform: 'skewX(53deg) scaleX(1.2) translateX(40px)',
  },
})

const ProjectCard = props => {
  const {
    children,
    title,
    media,
    excerpt,
    classes,
    className,
    index = 1,
    ...other
  } = props

  const titleClasses = [
    classes.variant1,
    classes.variant2,
    classes.variant3,
    classes.variant4,
  ]

  return (
    <Card className={classes.root} {...other}>
      {media && (
        <div className={classes.media}>
          <div />
          <img src={media} alt={title} />
        </div>
      )}
      <Typography
        className={clsx(classes.title, titleClasses[index % 4])}
        variant="h3"
        component="h2"
      >
        {title}
      </Typography>
    </Card>
  )
}

ProjectCard.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
}

ProjectCard.uiName = 'ProjectCard'

export default withStyles(styles)(ProjectCard)
