import React from 'react'
import PropTypes from 'prop-types'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
  useLocation,
  useHistory,
  useRouteMatch,
  Link as RouterLink,
} from 'react-router-dom'
import withStyles from '@material-ui/core/styles/withStyles'
import { IconButton, Typography } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import Skeleton from '@material-ui/lab/Skeleton'
import Container from 'components/Container'
import Project from 'components/Project'
import ProjectCard from 'components/ProjectCard'
import SimpleLink from 'components/SimpleLink'
import { decodeHTML } from 'components/utils'

export const styles = theme => ({
  root: {},
  header: {
    display: 'flex',
    flexDirection: 'column',

    position: 'sticky',
    top: 0,
    zIndex: 2,

    paddingBottom: theme.spacing(2),

    background:
      'linear-gradient(-180deg, #f8bbd0, #f8bbd0, #f8bbd0, transparent)',

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'flex-end',

      paddingBottom: theme.spacing(4),

      '& > div': {
        marginLeft: theme.spacing(2),
      },
    },
  },
  categories: {
    display: 'flex',
    alignItems: 'flex-end',

    [theme.breakpoints.up('sm')]: {
      alignItems: 'flex-start',
      flexDirection: 'column',
      minHeight: 0,

      '& > div:first-child': {
        width: 'auto',
      },
    },
  },
  back: {
    [theme.breakpoints.up('sm')]: {
      position: 'absolute',
      top: 0,
      left: 0,

      transform: 'translateX(-100%)',
    },
  },
  backIcon: {
    [theme.breakpoints.up('sm')]: {
      fontSize: 40,
    },
  },
})

const ScrollTo = ({ el, scroll, test }) => {
  React.useEffect(() => {
    if (el) {
      el.scroll(0, scroll)
    }
  }, [test])

  return <div />
}

const Work = props => {
  const { classes, posts, categories } = props
  const [activeCategory, setActiveCategory] = React.useState(false)
  const [scroll, setScroll] = React.useState(0)
  const history = useHistory()
  const ref = React.useRef()
  const { pathname } = useLocation()
  const isHome = pathname === '/work'

  React.useEffect(() => {
    const el = ref.current

    const handleScroll = e => {
      if (isHome) {
        setScroll(e.target.scrollTop)
      }
    }

    if (el) {
      el.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (el) {
        el.removeEventListener('scroll', handleScroll)
      }
    }
  }, [ref, isHome])

  if (posts.length === 0 || categories.length === 0) {
    return (
      <>
        <Skeleton />
        <Skeleton height={140} />
        <Skeleton height={30} />
        <Skeleton />
        <Skeleton />
      </>
    )
  }

  const handleClick = id => e => {
    e.preventDefault()

    setActiveCategory(id)
  }

  const filteredPosts = activeCategory
    ? posts.filter(p => p.categories.includes(activeCategory))
    : posts

  const handleSingleRoute = ({ match: { params } }) => {
    const { slug } = params
    const singlePost = posts.find(p => p.slug === slug)

    return (
      <>
        <ScrollTo el={ref && ref.current} scroll={0} test="single" />
        <div className={classes.back}>
          <div>
            <IconButton onClick={() => history.goBack()} color="inherit">
              <ArrowBack className={classes.backIcon} />
            </IconButton>
          </div>
        </div>
        <Project
          title={singlePost.title.rendered}
          content={singlePost.content.rendered}
        />
      </>
    )
  }

  return (
    <Container className={classes.root} ref={ref}>
      <Switch>
        <Route path="/work/:slug" render={handleSingleRoute} />
        <Route>
          <>
            <ScrollTo el={ref && ref.current} scroll={scroll} test="list" />
            <div className={classes.header}>
              <Typography variant="h3">Work</Typography>
              <div className={classes.categories}>
                <div>
                  {categories.map(cat => (
                    <SimpleLink
                      key={cat.slug}
                      onClick={handleClick(cat.id)}
                      active={activeCategory === cat.id}
                      href={`#${cat.slug}`}
                    >
                      {cat.name}
                    </SimpleLink>
                  ))}
                </div>
              </div>
            </div>
            {filteredPosts.map((post, postIdx) => (
              <ProjectCard
                key={post.slug}
                index={postIdx}
                title={decodeHTML(post.title.rendered)}
                media={
                  post.jetpack_featured_media_url !== ''
                    ? `${post.jetpack_featured_media_url}?w=1080`
                    : undefined
                }
                excerpt={post.excerpt.rendered}
                component={RouterLink}
                to={`/work/${post.slug}`}
              />
            ))}
          </>
        </Route>
      </Switch>
    </Container>
  )
}

Work.propTypes = {
  classes: PropTypes.object.isRequired,
}

Work.uiName = 'Work'

export default withStyles(styles)(Work)
