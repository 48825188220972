import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import withStyles from '@material-ui/styles/withStyles'
import { CircularProgress } from '@material-ui/core'

export const styles = theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%, 0)',
  },
})

const Loader = props => {
  const { children, classes, className, ...other } = props

  return (
    <div className={clsx(classes.root, className)} {...other}>
      <CircularProgress />
    </div>
  )
}

Loader.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
}

Loader.uiName = 'Loader'

export default withStyles(styles)(Loader)
