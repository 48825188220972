import { Effect, BlendFunction } from 'postprocessing'

const fragment = `void mainImage(const in vec4 inputColor, const in vec2 uv, out vec4 outputColor) {
    float sum = inputColor.r + inputColor.g + inputColor.b;

	outputColor = vec4(vec3(sum / 3.0), inputColor.a) * vec4(248.0 / 255.0, 187.0 / 255.0, 208.0 / 255.0, 1);
}`

export default class CustomEffect extends Effect {
  constructor() {
    super('CustomEffect', fragment, { blendFunction: BlendFunction.NORMAL })
  }
}
