// https://threejs.org/examples/webgl_panorama_equirectangular.html
// https://threejs.org/examples/misc_controls_deviceorientation.html
import * as THREE from 'three'
import { EffectComposer, EffectPass, RenderPass } from 'postprocessing'
import CustomEffect from './CustomEffect'
import { DeviceOrientationControls } from './DeviceOrientationControls.js'

var camera, scene, renderer, controls, composer, effect

var isUserInteracting = false,
  onMouseDownMouseX = 0,
  onMouseDownMouseY = 0,
  lon = 0,
  onMouseDownLon = 0,
  lat = 0,
  onMouseDownLat = 0,
  phi = 0,
  theta = 0,
  video = null,
  isMobile =
    window.DeviceOrientationEvent !== undefined &&
    typeof window.DeviceOrientationEvent.requestPermission === 'function'

function init() {
  var container, mesh

  container = document.getElementById('container')

  camera = new THREE.PerspectiveCamera(
    75,
    window.innerWidth / window.innerHeight,
    1,
    1100
  )
  if (isMobile) {
    controls = new DeviceOrientationControls(camera)
  } else {
    camera.target = new THREE.Vector3(0, 0, 0)
  }

  scene = new THREE.Scene()

  var geometry = new THREE.SphereBufferGeometry(500, 60, 40)
  // invert the geometry on the x-axis so that all of the faces point inward
  geometry.scale(-1, 1, 1)

  video = document.createElement('video')
  //   video.src =
  //     'https://cdn.dataverse.xyz/examples/allvizs/immersive/MaryOculus.mp4'
  //
  video.src = isMobile
    ? 'https://videos.files.wordpress.com/6GVHn2wo/output_hd.mp4'
    : 'https://videos.files.wordpress.com/6GVHn2wo/output.mp4'
  video.muted = true
  video.loop = true
  video.autoplay = true
  video.playsinline = true
  video.playsInline = true
  video.setAttribute('playsinline', true)
  video.autoload = true
  video.play()

  const texture = new THREE.VideoTexture(video)
  texture.minFilter = THREE.LinearFilter
  texture.magFilter = THREE.LinearFilter
  texture.image.crossOrigin = ''
  var material = new THREE.MeshBasicMaterial({
    map: texture,
    // color: 0xf8bbd0,
  })

  mesh = new THREE.Mesh(geometry, material)

  scene.add(mesh)

  //   var helperGeometry = new THREE.SphereBufferGeometry(500, 60, 40)
  //   var helperMaterial = new THREE.MeshBasicMaterial({
  //     color: 0xff00ff,
  //     wireframe: true,
  //   })
  //   var helper = new THREE.Mesh(helperGeometry, helperMaterial)
  //   scene.add(helper)

  renderer = new THREE.WebGLRenderer()
  renderer.setPixelRatio(window.devicePixelRatio)
  renderer.setSize(window.innerWidth, window.innerHeight)

  composer = new EffectComposer(renderer)
  composer.addPass(new RenderPass(scene, camera))

  effect = new CustomEffect()
  effect.blendMode.opacity.value = 0

  const pass = new EffectPass(camera, effect)
  pass.renderToScreen = true
  composer.addPass(pass)

  container.appendChild(renderer.domElement)

  if (!isMobile) {
    document.addEventListener('mousedown', onPointerStart, false)
    document.addEventListener('mousemove', onPointerMove, false)
    document.addEventListener('mouseup', onPointerUp, false)
    document.addEventListener('touchstart', onPointerStart, false)
    document.addEventListener('touchmove', onPointerMove, false)
    document.addEventListener('touchend', onPointerUp, false)
  }

  window.addEventListener('resize', onWindowResize, false)
}

function onWindowResize() {
  camera.aspect = window.innerWidth / window.innerHeight
  camera.updateProjectionMatrix()

  renderer.setSize(window.innerWidth, window.innerHeight)
}

function onPointerStart(event) {
  event.preventDefault()
  isUserInteracting = true

  var clientX =
    event.clientX || (event.touches && event.touches[0].clientX) || 0
  var clientY =
    event.clientY || (event.touches && event.touches[0].clientY) || 0

  onMouseDownMouseX = clientX
  onMouseDownMouseY = clientY

  onMouseDownLon = lon
  onMouseDownLat = lat
}

function onPointerMove(event) {
  event.preventDefault()
  if (isUserInteracting === true) {
    var clientX = event.clientX || event.touches[0].clientX
    var clientY = event.clientY || event.touches[0].clientY

    lon = (onMouseDownMouseX - clientX) * 0.1 + onMouseDownLon
    lat = (clientY - onMouseDownMouseY) * 0.1 + onMouseDownLat
  }
}

function onPointerUp() {
  isUserInteracting = false
}

function animate() {
  requestAnimationFrame(animate)
  update()
}

function update() {
  if (isMobile && controls) {
    controls.update()
  } else {
    if (isUserInteracting === false) {
      lon += 0.1
    }

    lat = Math.max(-85, Math.min(85, lat))
    phi = THREE.Math.degToRad(90 - lat)
    theta = THREE.Math.degToRad(lon)

    camera.target.x = 500 * Math.sin(phi) * Math.cos(theta)
    camera.target.y = 500 * Math.cos(phi)
    camera.target.z = 500 * Math.sin(phi) * Math.sin(theta)

    camera.lookAt(camera.target)
  }
  //   renderer.render(scene, camera)
  composer.render()
}

var startButton = document.getElementById('button')
var cancelButton = document.getElementById('cancel')

const boot = () => {
  //   startButton.parentNode.removeChild(startButton)
  //   cancelButton.parentNode.removeChild(cancelButton)
  init()
  animate()
}
startButton.addEventListener('click', e => {
  boot()
})
cancelButton.addEventListener('click', e => {
  isMobile = false
  boot()
})
if (!isMobile) {
  boot()
}

const setVideoSound = muted => {
  video.muted = muted
}
const setVideoEffect = value => {
  if (effect && effect.blendMode) {
    effect.blendMode.opacity.value = value
  }
}
window.setVideoSound = setVideoSound
window.setVideoEffect = setVideoEffect
