import { createMuiTheme } from '@material-ui/core/styles'
import { pink, indigo, red } from '@material-ui/core/colors'

const createOverrides = theme => ({
  MuiCssBaseline: {
    '@global': {
      html: {
        height: '100%',
      },
      body: {
        // cursor: 'url("/cursor.png"), auto',
        height: '100%',
        overflow: 'hidden',

        '&::-webkit-scrollbar-track': {
          border: '3px solid transparent',
          backgroundColor: 'transparent',
        },
        '&::-webkit-scrollbar': {
          width: '12px',
          backgroundColor: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgb(156, 55, 121)',
          borderLeft: `3px solid ${theme.palette.primary[100]}`,
        },
      },
      '#container': {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        pointerEvents: 'none',
        overflow: 'hidden',
        '& > *': {
          display: 'block',
          width: '100%',
          height: '100%',
          pointerEvents: 'none',
        },
      },
      '#root': {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      },
      a: {
        color: 'inherit',
      },

      '.a-loader': {},
    },
  },
  MuiBottomNavigation: {
    root: {},
  },
  MuiBottomNavigationAction: {
    root: {
      color: theme.palette.text.primary,
    },
    label: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 900,

      textTransform: 'uppercase',
    },
  },
  MuiCard: {
    root: {
      overflow: 'visible',
    },
  },
  MuiIconButton: {
    root: {
      color: theme.palette.text.primary,
    },
  },
})
const createTheme = () => {
  const heading = {
    fontWeight: 400,
    textTransform: 'uppercase',
  }
  const theme = createMuiTheme({
    palette: {
      primary: pink,
      secondary: indigo,
      error: red,
      contrastThreshold: 3,
      tonalOffset: 0.2,
      background: {
        paper: pink[100],
        default: '#000',
      },
      text: {
        primary: 'rgb(156, 55, 121)',
      },
    },
    typography: {
      fontFamily: '"Open Sans", sans-serif',
      h1: {
        ...heading,
      },
      h2: {
        ...heading,
      },
      h3: {
        ...heading,
      },
      h4: {
        ...heading,
      },
      h5: {
        ...heading,
      },
      h6: {
        ...heading,
      },
    },
    shape: {
      borderRadius: 0,
    },
    shadows: Array(25).fill('none'),
    projectCardMediaHeight: 300,
    appBarHeight: 56,
  })

  theme.overrides = createOverrides(theme)

  return theme
}

export default createTheme
