import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import withStyles from '@material-ui/styles/withStyles'
import { Typography, Link } from '@material-ui/core'

export const styles = theme => ({
  root: {
    color: theme.palette.text.primary,
    fontWeight: 900,

    '& + &:before': {
      content: '" / "',
    },
  },
  link: {
    color: theme.palette.text.primary,

    textTransform: 'uppercase',
  },
  active: {
    fontWeight: 900,
  },
})

const SimpleLink = props => {
  const { children, classes, className, active, ...other } = props

  return (
    <span className={clsx(classes.root, className)}>
      <Typography
        className={clsx(classes.link, active && classes.active)}
        variant="body2"
        component={Link}
        {...other}
      >
        {children}
      </Typography>
    </span>
  )
}

SimpleLink.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
}

SimpleLink.uiName = 'SimpleLink'

export default withStyles(styles)(SimpleLink)
