import React from 'react'
import WPAPI from 'wpapi'

const site = 'vincenttest699891792.wordpress.com'
const wp = new WPAPI({
  endpoint: 'https://public-api.wordpress.com',
})

const setCache = (data, key) => {
  if (!window.wpCache) {
    window.wpCache = {}
  }

  window.wpCache[key] = data
}

const getCache = key => {
  return window.wpCache && window.wpCache[key]
}

const useWP = (callback, initialValue = null, cacheKey = '') => {
  const [result, setResult] = React.useState(initialValue)

  React.useEffect(() => {
    let isMounted = true
    const fetch = async () => {
      const request = callback(wp)
      const response = await request.namespace(`wp/v2/sites/${site}`).get()

      if (isMounted) {
        setResult(response)

        if (cacheKey) {
          setCache(response, cacheKey)
        }
      }
    }
    if (cacheKey && getCache(cacheKey)) {
      setResult(getCache(cacheKey))
    } else {
      fetch()
    }

    return () => {
      isMounted = false
    }
  }, [callback])

  return result
}

export default useWP
