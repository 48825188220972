import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import withStyles from '@material-ui/styles/withStyles'
import imagesLoaded from 'imagesloaded'
import Loader from 'components/Loader'
import { Typography, Fade } from '@material-ui/core'

function DragScroller(slider) {
  let isDown = false
  let startX
  let scrollLeft

  const mouseDown = e => {
    isDown = true
    slider.classList.add('active')
    startX = e.pageX - slider.offsetLeft
    scrollLeft = slider.scrollLeft
  }
  const mouseLeave = () => {
    isDown = false
    slider.classList.remove('active')
  }
  const mouseMove = e => {
    if (!isDown) return
    e.preventDefault()
    const x = e.pageX - slider.offsetLeft
    const walk = (x - startX) * 1.5
    slider.scrollLeft = scrollLeft - walk
  }
  slider.addEventListener('mousedown', mouseDown)
  slider.addEventListener('mouseleave', mouseLeave)
  slider.addEventListener('mouseup', mouseLeave)
  slider.addEventListener('mousemove', mouseMove)

  const detach = () => {
    slider.removeEventListener('mousedown', mouseDown)
    slider.removeEventListener('mouseleave', mouseLeave)
    slider.removeEventListener('mouseup', mouseLeave)
    slider.removeEventListener('mousemove', mouseMove)
  }
  this.detach = detach
}
const scrollbars = theme => ({
  '&::-webkit-scrollbar': {
    width: '12px',
    backgroundColor: 'transparent',
  },
  '&::-webkit-scrollbar-track': {
    border: '3px solid transparent',
    backgroundColor: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgb(156, 55, 121)',
    borderLeft: `3px solid ${theme.palette.primary[100]}`,
  },
})
export const styles = theme => ({
  root: {
    maxHeight: `calc(100vh - ${theme.spacing(22)}px)`,
    overflow: 'hidden',
    overflowY: 'auto',

    ...scrollbars(theme),

    '& .wp-block-gallery': {
      padding: 0,
    },
    '& .blocks-gallery-grid': {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
    '& .blocks-gallery-item img': {
      display: 'block',

      width: '100%',
    },
    '& figure': {
      margin: 0,
    },
    '& img': {},
    '& .wp-block-embed-instagram': {
      textAlign: 'center',
    },
    '& .wp-block-embed-instagram .wp-block-embed__wrapper': {
      display: 'inline-block',
    },
    '& .wp-block-embed__wrapper > *:first-child': {
      position: 'relative',
      paddingBottom: `${((9 / 16) * 100).toFixed(2)}%`,

      '& > iframe': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
      },
    },

    /* Desktop */
    [theme.breakpoints.up('sm')]: {
      maxHeight: 'none',

      '& .blocks-gallery-grid': {
        display: 'flex',
        overflow: 'hidden',
        overflowX: 'auto',

        ...scrollbars(theme),
      },
      '& .blocks-gallery-item': {
        display: 'flex',
      },
      '& .blocks-gallery-item > figure': {
        display: 'flex',
      },
      '& .blocks-gallery-item img': {
        width: 'auto',
        height: `calc(100vh - ${theme.appBarHeight + theme.spacing(10)}px)`,
      },
      '& .blocks-gallery-caption-wrapper': {
        display: 'flex',
        alignItems: 'center',
      },
      '& .blocks-gallery-caption': {
        width: 250,
      },
    },
  },
  project: {
    opacity: 1,
    transition: 'opacity .75s',
  },
  projectLoading: {
    opacity: 0,
  },
  title: {
    position: 'absolute',
    top: 0,
    left: 0,

    color: 'white',
    mixBlendMode: 'difference',

    transform: 'skew(10deg, 10deg)',

    fontSize: '9rem',

    whiteSpace: 'nowrap',
    pointerEvents: 'none',

    zIndex: 2,
  },
})

const Project = props => {
  const { title, content, classes, className, ...other } = props

  const rootRef = React.useRef(null)
  const [isLoading, setIsLoading] = React.useState(true)

  React.useEffect(() => {
    if (content.indexOf('instagram') < 0 || !window.instgrm) {
      return
    }

    window.instgrm.Embeds.process()
  }, [content])

  React.useEffect(() => {
    imagesLoaded(rootRef.current, () => {
      const gallery = rootRef.current.querySelector('.blocks-gallery-grid')
      let slider = false
      if (gallery) {
        slider = new DragScroller(gallery)

        const caption = gallery.parentNode.querySelector(
          '.blocks-gallery-caption'
        )
        if (caption) {
          const el = document.createElement('div')
          el.classList.add('blocks-gallery-caption-wrapper')
          el.appendChild(caption)
          gallery.insertBefore(el, gallery.childNodes[0])
        }
      }

      setIsLoading(false)

      return () => {
        slider.detach()
      }
    })
  }, [rootRef])

  return (
    <div className={clsx(classes.root, className)} {...other}>
      {isLoading && <Loader />}
      <Fade in={!isLoading} timeout={1500}>
        <Typography
          variant="h3"
          className={classes.title}
          dangerouslySetInnerHTML={{ __html: title }}
        />
      </Fade>
      <Fade in={!isLoading}>
        <div
          ref={rootRef}
          className={clsx(classes.project, isLoading && classes.projectLoading)}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </Fade>
    </div>
  )
}

Project.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
}

Project.uiName = 'Project'

export default withStyles(styles)(Project)
