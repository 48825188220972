import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import withStyles from '@material-ui/styles/withStyles'

export const styles = theme => ({
  root: {
    maxHeight: `calc(100vh - ${theme.appBarHeight + theme.spacing(8)}px)`,
    overflow: 'hidden',
    overflowY: 'auto',

    '&::-webkit-scrollbar-track': {
      border: '3px solid transparent',
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar': {
      width: '12px',
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgb(156, 55, 121)',
      borderLeft: `3px solid ${theme.palette.primary[100]}`,
    },
  },
})

const Container = React.forwardRef(function Container(props, ref) {
  const { children, classes, className, ...other } = props

  return (
    <div className={clsx(classes.root, className)} {...other} ref={ref}>
      {children}
    </div>
  )
})

Container.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
}

Container.uiName = 'Container'

export default withStyles(styles)(Container)
