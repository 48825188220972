import React from 'react'
import { useLocation, useHistory, Link as RouterLink } from 'react-router-dom'
import { withStyles } from '@material-ui/styles'
import {
  Fade,
  BottomNavigation,
  BottomNavigationAction,
  AppBar,
  Paper,
  IconButton,
  Link,
} from '@material-ui/core'
import {
  Cancel,
  VolumeOff,
  VolumeUp,
  Facebook,
  Instagram,
  Twitter,
  Mail,
} from '@material-ui/icons'
import useWP from 'components/useWP'
import Work from 'views/Work'
import Page from 'views/Page'
import TikTok from 'components/icons/TikTok'
import Soundcloud from 'components/icons/Soundcloud'

export const styles = theme => ({
  root: {
    overflow: 'hidden',
  },
  about: {},
  paperWrap: {
    position: 'relative',
    maxWidth: 640,
    margin: '0 auto',
    zIndex: 1,

    [theme.breakpoints.up('sm')]: {
      maxWidth: 1000,

      '$about &': {
        maxWidth: 640,
      },
    },
  },
  paper: {
    position: 'relative',
    margin: theme.spacing(2),
    zIndex: 1,
  },
  appBar: {
    top: 'auto',
    bottom: 0,
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  container: {
    position: 'relative',
    padding: theme.spacing(2),
    maxHeight: `calc(100vh - ${56 + theme.spacing(4)}px)`,
  },
  close: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),

    zIndex: 3,

    [theme.breakpoints.up('sm')]: {
      top: 0,
      right: 0,

      transform: 'translateX(100%)',
    },
  },
  closeIcon: {
    [theme.breakpoints.up('sm')]: {
      fontSize: 40,
    },
  },
  sound: {
    flex: 'none',
    minWidth: 'auto',
  },
  social: {
    display: 'flex',
    position: 'fixed',
    bottom: 56 + theme.spacing(2),
    right: theme.spacing(2),

    flexDirection: 'column',

    color: theme.palette.primary[100],
    '& a': {
      color: theme.palette.primary[100],
    },
    // color: 'white',
    // mixBlendMode: 'difference',
  },
})

const Empty = () => {
  return null
}

const App = ({ classes }) => {
  const [visible, setVisible] = React.useState(false)
  const [soundOn, setSoundOn] = React.useState(false)
  const { pathname } = useLocation()
  const history = useHistory()
  const component = pathname.substr(1).split('/')[0] || 'empty'

  React.useEffect(() => {
    if (visible) {
      window.setVideoEffect(1)
    } else {
      window.setVideoEffect(0)
    }
  }, [visible])

  React.useEffect(() => {
    if (component === 'empty' && visible) {
      setVisible(false)
    } else if (component !== 'empty' && !visible) {
      setVisible(true)
    }
  }, [visible, component])

  const posts = useWP(
    React.useCallback(wp => wp.posts().perPage(100), []),
    [],
    'posts'
  )

  const categories = useWP(
    React.useCallback(wp => wp.categories(), []),
    [],
    'categories'
  )

  const sortedCategories = categories.sort(
    (a, b) => Number(a.description) - Number(b.description)
  )

  const handleClose = () => {
    history.push('/')
    setVisible(false)
  }

  const handleSoundToggle = React.useCallback(() => {
    setSoundOn(on => {
      window.setVideoSound(on)

      return !on
    })
  }, [setSoundOn])

  const views = {
    empty: () => <Empty />,
    work: React.useCallback(
      () => <Work posts={posts} categories={sortedCategories} />,
      [sortedCategories, posts]
    ),
    about: React.useCallback(() => <Page slug="about" />, []),
    cv: React.useCallback(() => <Page slug="cv" />, []),
  }
  const Component = views[component]

  return (
    <div className={(classes.root, classes[component])}>
      <Fade in={visible}>
        <div className={classes.paperWrap}>
          <Paper className={classes.paper}>
            <div className={classes.container}>
              <IconButton className={classes.close} onClick={handleClose}>
                <Cancel className={classes.closeIcon} />
              </IconButton>

              <Component />
            </div>
          </Paper>
        </div>
      </Fade>

      <div className={classes.social}>
        <IconButton
          color="inherit"
          component={Link}
          href="https://www.facebook.com/arvidabystromartist"
        >
          <Facebook />
        </IconButton>
        <IconButton
          color="inherit"
          component={Link}
          href="https://twitter.com/arvidabystrom"
        >
          <Twitter />
        </IconButton>
        <IconButton
          color="inherit"
          component={Link}
          href="https://www.tiktok.com/@arvidabystrom"
        >
          <TikTok />
        </IconButton>
        <IconButton
          color="inherit"
          component={Link}
          href="https://www.instagram.com/arvidabystrom"
        >
          <Instagram />
        </IconButton>
        <IconButton
          color="inherit"
          component={Link}
          href="https://soundcloud.com/arvidabystrom"
        >
          <Soundcloud />
        </IconButton>
        <IconButton
          color="inherit"
          component={Link}
          href="mailto:emma.arvida.elisabeth@gmail.com"
        >
          <Mail />
        </IconButton>
      </div>

      <AppBar className={classes.appBar} color="inherit">
        <BottomNavigation showLabels>
          <BottomNavigationAction
            value="work"
            label="Work"
            component={RouterLink}
            to="/work"
          />
          <BottomNavigationAction
            value="about"
            label="About"
            component={RouterLink}
            to="/about"
          />
          <BottomNavigationAction
            value=""
            label="Shop"
            component="a"
            href="https://arvidabystrom.squarespace.com"
            target="_blank"
          />
          <BottomNavigationAction
            className={classes.sound}
            onClick={handleSoundToggle}
            icon={soundOn ? <VolumeUp /> : <VolumeOff />}
            value=""
          />
        </BottomNavigation>
      </AppBar>
    </div>
  )
}

export default withStyles(styles)(App)
