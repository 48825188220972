import React from 'react'
import { SvgIcon } from '@material-ui/core'

const Soundcloud = React.memo(
  React.forwardRef((props, ref) => (
    <SvgIcon ref={ref} {...props}>
      <path
        d="M20.2,11.2c-0.3,0-0.5,0-0.8,0.1c-0.3-3.4-3.2-6.1-6.7-6.1C12.3,5.2,12,5.6,12,6v12c0,0.4,0.3,0.8,0.8,0.8h7.5
        c2.1,0,3.8-1.7,3.8-3.8C24,12.9,22.3,11.2,20.2,11.2z"
      />
      <path d="M9,7.5V18c0,0.4,0.3,0.8,0.8,0.8s0.8-0.3,0.8-0.8V7.5c0-0.4-0.3-0.8-0.8-0.8S9,7.1,9,7.5z" />
      <path d="M6,10.5V18c0,0.4,0.3,0.8,0.8,0.8s0.8-0.3,0.8-0.8v-7.5c0-0.4-0.3-0.8-0.8-0.8S6,10.1,6,10.5z" />
      <path d="M3,18c0,0.4,0.3,0.8,0.8,0.8s0.8-0.3,0.8-0.8v-7.5c0-0.4-0.3-0.8-0.8-0.8S3,10.1,3,10.5V18z" />
      <path d="M0.8,18.8c0.4,0,0.8-0.3,0.8-0.8v-4.5c0-0.4-0.3-0.8-0.8-0.8S0,13.1,0,13.5V18C0,18.4,0.3,18.8,0.8,18.8z" />
    </SvgIcon>
  ))
)
Soundcloud.displayName = 'SoundcloudIcon'
Soundcloud.uiName = 'SoundcloudIcon'

export default Soundcloud
